import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"

export const HeaderBrand: React.FC = memo(() => {
  const { organisation } = useStaticQuery<GatsbyTypes.StaticHeaderBrandQuery>(graphql`
    query StaticHeaderBrand {
      organisation: sanitySettingOrganisation {
        title
      }
    }
  `)

  return (
    <Link
      title={organisation?.title}
      aria-label={organisation?.title}
      to="/"
      transition="unset"
      sx={{
        ":focus": {
          outline: "none",
        },
        ":focus-visible": {
          outline: "5px auto #0065BE",
          outlineOffset: "5px",
        },
      }}
    >
      <Icon name="brand/logo" title={organisation?.title} />
    </Link>
  )
})
