module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0__encoding@0.1.1_jgxnxz25usk55xacapawesf3nu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"Fine-Day","short_name":"Fine-Day","description":"For Good Nights And Slow Mornings","background_color":"#B4B3B4","theme_color":"#FFFFFF","display":"minimal-ui","icon":"static/images/icon.png","icons":[{"src":"static/images/icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f73122b854d60047419ca5bd381611c2"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0__encoding@0._xt77jlbvdanx2m2foeztqlxg2u/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-loadable-components-ssr@4.3.2_@babel+core@7.26.7_@loadable+component@5.16.4_rea_olqhovo2cpl4wqw362k7rapq3y/node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._ga2vigrsll3uxpxmk262axrk2u/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
