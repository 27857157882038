import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, DrawerBody, Flex, Heading, HStack, IconButton, Text, VStack } from "@chakra-ui/react"
import { Sensors, useSearch } from "@usereactify/search"

import { useAppContext } from "@app/providers/app"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { SearchFormRelated } from "./Related/SearchFormRelated"
import { SearchFormResults } from "./Results/SearchFormResults"
import { FormButton } from "@app/components/Form/FormButton"
import { FormInput } from "@app/components/Form/FormInput"
import { Icon } from "@app/components/Icon"
import { Loader } from "@app/components/Loader/Loader"
import { useGlobalContext } from "@app/providers/global"

export const SearchFormContent: React.FC = memo(() => {
  const { additionalClose } = useGlobalContext()
  const { search } = useStaticQuery<GatsbyTypes.StaticSearchFormContentQuery>(graphql`
    query StaticSearchFormContent {
      search: sanityPageSearch {
        title
        popular
        additionalPopular
        additionalPlaceholder
        additionalRecent
        additionalSuggested
        additionalSubmit
      }
    }
  `)
  const input = useRef()
  const [results, setResults] = useState<boolean>(false)
  const [value, setValue] = useState<string>("")
  const { dispatch, state, activeSearches, setActiveSearches } = useAppContext()
  const { trackSearch } = useAnalytics()
  const { searchTerm, setSearchTerm } = useSearch()

  const handleClick = useCallback(() => {
    dispatch({
      type: "search",
      payload: !state.activeSearch,
    })
  }, [dispatch, state])

  const handleChange = useCallback(({ target: { value } }) => setValue(value), [setValue])

  const handleClear = useCallback(() => {
    setValue("")
    input?.current && input.current.focus()
  }, [input, setValue])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()

      if (value) {
        setSearchTerm(value)
        trackSearch(value)
        handleClear()
      }
    },
    [handleClear, setSearchTerm, trackSearch, value]
  )

  const handleSuggestion = useCallback(
    value => {
      setValue(value)
      setSearchTerm(value)
      trackSearch(value)
      handleClear()
    },
    [handleClear, setSearchTerm, trackSearch]
  )

  // TODO: source suggestions dynamically
  const suggestions: Array<string> = []

  // Intentionally only run at searchTerm change
  useEffect(() => {
    if (searchTerm) {
      const searches = [searchTerm, ...(activeSearches?.filter(term => term !== searchTerm) || [])].slice(0, 4)

      setActiveSearches(searches)
    }

    if (searchTerm && !results) setTimeout(() => setResults(true), 1000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  return (
    <>
      <Box
        pos="absolute"
        bg="grey.cloud"
        h="100vh"
        inset={0}
        transition="transform 0.2s ease"
        transform={`translateY(${!searchTerm ? 100 : 0}%)`}
        w="full"
        pointerEvents="none"
      />
      <DrawerBody
        as={Flex}
        bg="transparent"
        color={searchTerm ? "unset" : "white"}
        alignItems="stretch"
        justifyContent="space-between"
        flexDirection="column"
        p={0}
        pos="relative"
        transition="color 0.2s ease"
      >
        <Flex alignItems="flex-start" justifyContent="flex-end" px={[4.25, 12]} pt={[4.25, 12, 25]}>
          <FormButton
            onClick={handleClick}
            p={2}
            title={additionalClose}
            aria-label={additionalClose}
            transition="color 0.2s ease"
            variant={searchTerm ? "ghost" : "ghostInverted"}
          >
            <Icon name="close" width={5} />
          </FormButton>
        </Flex>
        <VStack
          as="form"
          flex={1}
          onSubmit={handleSubmit}
          px={[6.25, 29, 22.5]}
          pt={4}
          pb={[13.75, 16.25, 25]}
          alignItems="center"
          justifyContent={searchTerm ? "space-between" : "flex-start"}
          spacing={[6, 4]}
        >
          <Heading
            as="p"
            color={searchTerm ? "grey.mid" : "unset"}
            maxW={280}
            fontSize="xl"
            lineHeight="35px"
            size="h4"
            textAlign="center"
            transition="color 0.2s ease"
          >
            {search?.title}
          </Heading>
          <Box maxW={920} w="full">
            <FormInput
              autoComplete="off"
              autoFocus
              background="grey.mid"
              containerProps={{ w: "full" }}
              name="q"
              onChange={handleChange}
              placeholder={search?.additionalPlaceholder}
              reference={input}
              rightAddon={
                <HStack alignItems="center" justifyContent="flex-end" pb={2} spacing={1}>
                  <IconButton
                    display="block"
                    icon={<Icon name="search" width={4} color={!results && "grey.white"} />}
                    title={search?.additionalSubmit || ""}
                    aria-label={search?.additionalSubmit || ""}
                    type="submit"
                    color={searchTerm ? "grey.mid" : "unset"}
                    variant={searchTerm ? "ghost" : "ghostInverted"}
                  />
                </HStack>
              }
              value={value}
              variant={searchTerm ? "minimal" : "minimalInverted"}
            />
            {!searchTerm && (
              <VStack
                alignItems="flex-start"
                justifyContent="flex-start"
                pos="relative"
                mt={5}
                overflow="hidden"
                spacing={10}
                transform={`translateY(${searchTerm ? 100 : 0}%)`}
                transition="all 0.2s ease"
              >
                {!value && search?.popular?.length > 0 && (
                  <Box>
                    <Text size="xs" textAlign="left" variant="caps" mb={2}>
                      {search?.additionalPopular}
                    </Text>
                    <HStack alignItems="flex-start" justifyContent="flex-start" spacing={4}>
                      {search.popular.map(term => (
                        <FormButton
                          key={term}
                          onClick={() => handleSuggestion(term)}
                          fontSize="xs"
                          fontWeight={600}
                          letterSpacing="generous"
                          textTransform="none"
                          variant="ghostInverted"
                        >
                          {term}
                        </FormButton>
                      ))}
                    </HStack>
                  </Box>
                )}
                {value && suggestions?.length > 0 && (
                  <Box>
                    <Text size="xs" textAlign="left" variant="caps" mb={2}>
                      {search?.additionalSuggested}
                    </Text>
                    <VStack alignItems="flex-start" justifyContent="flex-start" spacing={4}>
                      {suggestions.map(term => (
                        <FormButton
                          key={term}
                          onClick={() => handleSuggestion(term)}
                          fontSize="xs"
                          fontWeight={600}
                          letterSpacing="generous"
                          textTransform="none"
                          variant="ghostInverted"
                        >
                          {term}
                        </FormButton>
                      ))}
                    </VStack>
                  </Box>
                )}
                {activeSearches?.length > 0 && (
                  <Box>
                    <Text size="xs" textAlign="left" variant="caps" mb={2}>
                      {search?.additionalRecent}
                    </Text>
                    <VStack alignItems="flex-start" justifyContent="flex-start" spacing={4}>
                      {activeSearches.map(term => (
                        <FormButton
                          key={term}
                          onClick={() => handleSuggestion(term)}
                          fontSize="xs"
                          fontWeight={600}
                          letterSpacing="generous"
                          textTransform="none"
                          variant="ghostInverted"
                        >
                          {term}
                        </FormButton>
                      ))}
                    </VStack>
                  </Box>
                )}
              </VStack>
            )}
            <Sensors />
          </Box>
          {searchTerm && (
            <Box
              flex={1}
              pt={4}
              w="full"
              sx={{
                "&> * ": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: !results ? "center" : "stretch",
                  justifyContent: !results ? "center" : "flex-start",
                  h: "full",
                },
              }}
            >
              {!results ? (
                <VStack alignItems="center" justifyContent="center" w="full">
                  <Loader w={34} />
                </VStack>
              ) : (
                <SearchFormResults setValue={setValue} />
              )}
            </Box>
          )}
        </VStack>
        {!value && (
          <VStack alignItems="center" justifyContent="center" bg="grey.cloud" color="grey.darker" px={6.25} w="full">
            <SearchFormRelated />
          </VStack>
        )}
      </DrawerBody>
    </>
  )
})
