import React, { memo, useEffect, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AspectRatio, Box, Flex, GridItem, Heading, SimpleGrid, Skeleton, SkeletonText } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import { useLazyQuery } from "@apollo/client"
import "swiper/css"

import { useCartContext } from "@app/providers/cart"
import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"
import { useShopify } from "@app/hooks/useShopify"
import { FormButton } from "@app/components/Form/FormButton"
import { ProductCard } from "@app/components/Product/Card/ProductCard"
import { Icon } from "@app/components/Icon"
import { useGlobalContext } from "@app/providers/global"

// eslint-disable-next-line react-hooks/rules-of-hooks
SwiperCore.use([Navigation])

export const SearchFormRecommendations: React.FC = memo(() => {
  const { countryCode, cart } = useCartContext()
  const { additionalPrevious, additionalNext } = useGlobalContext()
  const { search } = useStaticQuery<GatsbyTypes.StaticSearchFormRecommendationsQuery>(graphql`
    query StaticSearchFormRecommendations {
      search: sanityPageSearch {
        additionalRecommended
        products {
          shopify {
            id
          }
        }
      }
    }
  `)
  const {
    graphql: {
      queries: { GET_PRODUCT_RECOMMENDATIONS },
    },
    helpers: { encodeShopifyId },
  } = useCore()
  const { isBase } = useMedia()
  const { productNormaliser } = useShopify()

  const id = useMemo(
    () =>
      cart?.lines?.map(
        ({
          merchandise: {
            product: { id },
          },
        }) => id
      )?.[0] ||
      search?.products?.map(product => encodeShopifyId(product?.shopify?.id, "Product"))?.[0] ||
      null,
    [cart?.lines, encodeShopifyId, search?.products]
  )

  const [getRecommendations, { data, called, loading, error }] = useLazyQuery(GET_PRODUCT_RECOMMENDATIONS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      countryCode,
      id,
      firstMedia: 1,
      firstImages: 1,
      firstVariants: 1,
      firstMetafields: 1,
      firstCollections: 1,
      metafieldIdentifiers: [],
    },
  })

  useEffect(() => {
    if (!id || called) return
    getRecommendations()
  }, [id, called, getRecommendations])

  if (error) console.error(error)

  const recommendations = !loading && data?.recommendations?.length ? data.recommendations.map(productNormaliser).slice(0, 4) : []

  return recommendations?.length > 0 || loading ? (
    <Flex pos="relative" flexDirection="column" alignItems="stretch" justifyContent="center" pt={[13.75, 16.25, 25]} w="full">
      <Heading as="p" color="grey.lake" fontSize="sm" mb={[2, 8]} size="h6" textAlign="center">
        {search?.additionalRecommended}
      </Heading>
      <Box
        d={[
          recommendations?.length === 0 || recommendations?.length > 2 ? "block" : "none",
          recommendations?.length === 0 || recommendations?.length > 3 ? "block" : "none",
        ]}
        pos={["static", "absolute"]}
        inset={0}
        mt={[0, 12]}
        mb={[9, 0]}
        mx={["unset", -18, -15]}
      >
        <Flex alignItems="center" justifyContent={["center", "space-between"]} h={["auto", "full"]}>
          <FormButton zIndex={1} id={`search-form-recommendations-left`} p={4} variant="ghost" title={additionalPrevious}>
            <Icon name="chevron-left" width={6} />
          </FormButton>
          <FormButton zIndex={1} id={`search-form-recommendations-right`} p={4} variant="ghost" title={additionalNext}>
            <Icon name="chevron-right" width={6} />
          </FormButton>
        </Flex>
      </Box>
      {recommendations?.length > 0 ? (
        <>
          {(isBase && recommendations.length > 2) || (!isBase && recommendations.length > 3) ? (
            <Box
              sx={{
                ".swiper": { w: "full" },
                ".swiper-slide": { overflow: "hidden" },
                ".swiper-button-disabled": { opacity: 0, visibility: "hidden" },
              }}
            >
              <Swiper
                breakpoints={{ 768: { slidesPerView: 3 } }}
                cssMode
                navigation={{ prevEl: `#search-form-recommendations-left`, nextEl: `#search-form-recommendations-right` }}
                slidesPerView={2}
                spaceBetween={20}
              >
                {recommendations.map((item, index) => (
                  <SwiperSlide key={item?.id}>
                    <ProductCard item={item} pagePosition={index} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          ) : (
            <Flex alignItems="flex-start" justifyContent="center" mx={-2.5}>
              {recommendations.map((item, index) => (
                <ProductCard key={item?.id} item={item} pagePosition={index} px={2.5} w={[1 / 2, 1 / 3]} />
              ))}
            </Flex>
          )}
        </>
      ) : (
        <SimpleGrid columns={[2, 3]} gap={5}>
          {Array.from(Array(isBase ? 2 : 3).keys()).map((_, index) => (
            <GridItem key={index} colSpan={1}>
              <AspectRatio ratio={320 / 450} mb={6}>
                <Skeleton />
              </AspectRatio>
              <SkeletonText w="full" />
            </GridItem>
          ))}
        </SimpleGrid>
      )}
    </Flex>
  ) : null
})
